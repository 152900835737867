<template>
    <div class="py-2">
        <v-layout style="padding: 0 32px 0 24px; height: 50px;" justify-space-between align-center>
            <v-flex d-flex xs6>
                <span class="text-truncate">Your credit or debit cards</span>
            </v-flex>
            <v-flex xs5>
                <v-layout fill-height justify-center>
                    <v-flex xs12 sm12 md4 d-flex align-center style="text-align: center;">
                        <span>Default</span>
                    </v-flex>
                    <v-flex class="hidden-sm-and-down" xs0 sm0 md8 style="text-align: right;">
                        <span>Expires</span>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <div style="height: 200px;" v-if="loading">
            <Spin ></Spin>
        </div>
        <template v-else-if="creditCardList.length" >
            <v-expansion-panel v-for="(item ,i) in creditCardList" :value="[true]" expand disabled  :key="i" focusable>
                <v-expansion-panel-content>
                    <template v-slot:header>
                        <v-layout justify-space-between style="color: initial">
                            <v-flex xs6>
                                <v-layout >
                                    <v-flex shrink d-flex align-center>
                                        <i :class="`fa fa-cc-${item.brand.toLowerCase()}`" style="font-size: 25px"
                                           aria-hidden="true"></i>
                                    </v-flex>
                                    <v-flex grow d-flex align-center>
                                        <span class="pl-3 text-truncate">Credit Card Ending in {{item.last4}}</span>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                            <v-flex xs5>
                                <v-layout  justify-center>
                                    <v-flex xs12 sm12 md4 d-flex align-center class="text-xs-right text-md-center">
                                        <v-icon color="success">done</v-icon>
                                    </v-flex>
                                    <v-flex class="hidden-sm-and-down" xs0 sm0 md8
                                            style="display:flex;justify-content: flex-end;align-items: center;text-align: right;">
                                        <span class="ml-2"></span>
                                        <span class="ml-2">{{item.exp_month}}/{{item.exp_year}}</span>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </template>
                    <v-card>
                        <v-card-text>
                            <v-layout column>
                                <v-flex xs12 style="display: block" px-4 py-3>
                                    <SmallBlock title="Name on this card">{{item.name}}</SmallBlock>
                                </v-flex>
                                <v-flex xs12 style="display: block" px-4>
                                    <v-layout>
                                        <v-flex grow>
                                            <v-checkbox v-model="isDefault" disabled="true" label="As default payment method"></v-checkbox>
                                        </v-flex>
                                        <v-flex shrink d-flex align-center>
                                            <v-btn small color="blue-grey" class="white--text text-none" @click="$refs.addcard.open()">Update</v-btn>
                                            <v-btn small color="blue-grey"  @click="$refs.deleteconfirm.open()" class="text-none white--text">Remove</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </template>
        <div style="height: 200px;" v-else>
            <Empty>You have yet to add your credit or debit card </Empty>
<!--            <span style="display: inline-flex;align-items: center;">You have yet to add your credit or debit card</span>-->
        </div>
        <div style="margin: 0;">
            <v-btn color="primary" v-show="!creditCardList.length && !loading"  @click="$refs.addcard.open()">Add Credit Card</v-btn>
            <img style="display: block;margin-top: 20px;" src="./bank.png"/>
        </div>
        <AddCard ref="addcard" @confirm="createCreditCard"></AddCard>
        <v-divider></v-divider>
        <Confirm ref="deleteconfirm" @confirm="removeCreditCard">
            <span slot="title">Confirmation</span>
            <template v-slot:content="options">
                Are you sure you want to delete your credit card?
            </template>
        </Confirm>
    </div>
</template>

<script>
    import Spin from '@/components/Spin'
    import Empty from '@/components/Empty'
    import SmallBlock from '@/components/SmallBlock.vue'
    import AddCard from './_souce/AddCard.vue'
    import Confirm from '@/components/Confirm.vue'

    export default {
        name: "Credit",
        components: {
            Empty,
            SmallBlock,
            AddCard,
            Spin,
            Confirm
        },
        data() {
            return {
                loading: false,
                dialog: false,
                isDefault: true,
                creditCardList: [],
            }
        },
        methods: {
            initCreditCard() {
                this.loading = true
                this.$http({
                    method: 'get',
                    url: `/credit-cards/?client_id=${this.$store.state.client.clientInfo.id}`,
                }).then(res => {
                    this.loading = false
                    this.creditCardList = res.results
                }).catch(() => {
                    this.loading = false
                    this.$message.error('Oops, it failed to get your Credit Card information. Please contact us at support@cloudclusters.io for this issue.')
                })
            },
            removeCreditCard() {
                return this.$http.delete(`/credit-cards/${this.creditCardList[0].uuid}/`).then(() => {
                    this.initCreditCard()
                    this.$message.success('Your Credit Card payment method was removed successfully.')
                }).catch(() => {
                    this.$message.error('Oops, it failed to remove your Credit Card payment method, please contact us at support@cloudclusters.io for this issue.')
                })
            },
            createCreditCard(obj) {
                return this.$http({
                    method: 'post',
                    url: `/credit-cards/`,
                    data: obj
                }).then(() => {
                    this.$message.success('Your Credit Card payment method was created successfully.')
                    this.initCreditCard()
                    return Promise.resolve()
                }).catch(() => {
                    this.$message.error('Oops, it failed to add your Credit Card, please contact us at support@cloudclusters.io for this issue.')
                    return Promise.reject()
                })
            }
        },
        mounted() {
            this.initCreditCard()
        },
    }
</script>

<style lang="scss">

</style>
