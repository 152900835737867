<template>
    <v-dialog v-model="dialog" max-width="600" persistent no-click-animation>
        <v-form ref="form">
            <v-card>
                <v-card-title>
                    <span v-if="isUpdate"  class="title">Update Card</span>
                    <span v-else class="title">Add Your Credit or Debit Card</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-layout column>
                        <v-flex class="mt-2">
                            <v-text-field
                                    v-model="creditCardName"
                                    label="Name on card"
                                    clearable
                                    :rules="[rules.required]"
                            ></v-text-field>
                        </v-flex>
                        <v-flex class="mt-3">
                            <StripeCard ref="stripecard" @tokenChange="cardChange"></StripeCard>
                        </v-flex>
                        <v-flex style="height: 22px">
                            <span v-if="cardEvent.error" :style="{color: $vuetify.theme.error}">{{cardEvent.error.message}}</span>
                        </v-flex>
                        <v-flex>
                            <v-checkbox v-model="isDefault" disabled label="Save as default payment method"></v-checkbox>
                        </v-flex>
                    </v-layout>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="text-none" color="blue darken-1" flat @click="close">Close</v-btn>
                    <v-btn class="text-none" color="primary" @click="submitCredit" :loading="loading">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import StripeCard from "@/components/StripeCard"
    import rules from '@/module/rules/index.js'

    export default {
        name: "AddCard",
        components: {StripeCard},
        mixins: [loadingMixin],
        props: {
            cardDetail: Object
        },
        data() {
            return {
                isUpdate: false,
                isDefault: true,
                creditCardName: '',
                rules: {...rules},
                cardEvent: {},
                formData: {
                    stripe_token: '',
                    client_id: ''
                }
            }
        },
        watch: {
            dialog(val) {
                if (!val) {
                    this.cardEvent = {}
                    this.creditCardName = ''
                    this.isUpdate = false
                    this.$refs.stripecard.card.clear()
                    this.$refs.form.resetValidation()
                }
            }
        },
        methods: {
            updateOpen() {
                this.open()
                this.isUpdate = true
            },
            cardChange(event) {
                if (event) {
                    this.cardEvent = event
                }
            },
            submitCredit() {
                if (!this.$refs.form.validate() || !this.cardEvent.complete || this.cardEvent.error) {
                    return
                }
                this.loading = true
                const stripeComp = this.$refs.stripecard
                stripeComp.stripe.createToken(stripeComp.card, {name: this.creditCardName}).then(({token,error}) => {
                    if (token) {
                        this.formData.stripe_token = token.id
                        this.formData.client_id = this.$store.state.client.clientInfo.id
                        this.ok()
                    } else if (error) {
                        this.$message.error(error.message)
                        this.loading = false
                    }
                }).catch(err => {
                    this.$message.error(err.detail)
                    this.loading = false
                })
            }
        }
    }
</script>

<style lang="scss">

</style>
